var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-recompenses" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("h1", [_vm._v(_vm._s(_vm.$t("rewards.title")))]),
        _vm._l(_vm.getRacesToEvaluate, function (race) {
          return _c(
            "div",
            { key: race.id, class: _vm.raceClass(race) },
            [
              race.done.length === 0
                ? _c("span", { staticClass: "invalid" })
                : _vm._e(),
              _c(
                race.done.length === 0 ? "span" : "router-link",
                {
                  tag: "router-link",
                  attrs: {
                    to: { name: "reward", params: { race_id: race.id } },
                  },
                },
                [
                  _c("button", [
                    _vm._v(_vm._s(_vm.getRaceLabel(_vm.getRaceById(race.id)))),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        _c("div", { staticClass: "actions" }, [
          _vm.canValidate
            ? _c(
                "button",
                {
                  staticClass: "action validate",
                  on: { click: _vm.onValidate },
                },
                [_vm._v(" " + _vm._s(_vm.$t("rewards.go_to_summary")) + " ")]
              )
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }